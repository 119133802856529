import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Typewriter } from "react-simple-typewriter";
import gifLogo from "../images/logo_gif.mp4";
import bgImage from "../images/features-download-bg.png";
import "./css/Features.css";

function Features() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="myStyle">
      <div className="maxContainer">
        <div className="boxContainer">
          <div className="textContainer">
            <div className="iconStyle">
              <FontAwesomeIcon icon={faPaperclip} />
            </div>
            {isMobile ? (
              <span>
                Can you analyze my recent lab results and provide insights?
              </span>
            ) : (
              <Typewriter
                words={[
                  "Can you analyze my recent lab results and provide insights?",
                  "What lifestyle changes could improve my condition?",
                  "What are some daily wellness routines I could follow to stay healthy?",
                ]}
                loop={0}
                cursor
                cursorStyle="_"
                typeSpeed={70}
              />
            )}
          </div>
          <div>
            <video className="gifStyle" autoPlay muted loop>
              <source src={gifLogo} />
            </video>
          </div>
        </div>
      </div>
      <div className="movingTextWrapper">
        <div className="movingText">
          <span className="movingTextSpan">
            Fueled by the elite giants of the industry, setting a new benchmark
            in excellence.
          </span>
        </div>
        <div className="movingText">
          <span className="movingTextSpan movingTextSpan2">
            Fueled by the elite giants of the industry, setting a new benchmark
            in excellence.
          </span>
        </div>
      </div>
      <div className="maxContainer">
        <div
          className="downloadBoxContainer"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="csshape csshape_1"></div>
          <div className="csshape csshape_2"></div>
          <div className="csshape csshape_3"></div>
          <h1 className="downloadBoxContainerh1">
            Discover the power of{"  "}
            <em>Decmeds</em> – try our app today!
          </h1>
          <button className="downloadButton">
            Download Now &nbsp;
            <FontAwesomeIcon className="icon" icon={faArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Features;
