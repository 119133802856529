import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./css/Hero.css";

function Hero() {
  return (
    <div className="hero-section">
      <section className="hero" id="hero">
        <div className="max-width">
          <div className="home-content">
            <h1 className="text-1">
              Book Your{" "}
              <span className="text-animation blue-text">Appointment</span>
              <div className="csshape csshape_4"></div>
            </h1>
            <div className="text-2">
              <h1 className="text-ai">
                And Also Get <p className="blue-text">AI-Backed Expertise</p>
              </h1>
            </div>
            <p className="text-home">
              Why Wait? Bring Intelligent Care Right to Your Fingertips!
              Download <span className="blue-text text-600">Decmeds</span> Now
              for{" "}
              <span className="blue-text text-600">
                Instant Health Solutions
              </span>{" "}
              and{" "}
              <span className="blue-text text-600">
                Easy Doctor Appointments
              </span>{" "}
              – Anytime, Anywhere!
            </p>
            <div className="home-btn-box">
              <a href="#" className="home-btn text-600">
                Chat Now &nbsp;
                <span className="arrow-right">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
