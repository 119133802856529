import './App.css';
import AnimatedCursor from 'react-animated-cursor'
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Contact from './components/Contact';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsNC from './components/TermsNC';
import Counter from './components/Counter';
import Whyus from './components/Whyus';

function App() {
  return (
    <div className="App">
      {/* <AnimatedCursor
        innerSize={16}
        outerSize={28}
        innerStyle={{ backgroundColor: '#0d55c1', zIndex: '100000000' }}
        outerStyle={{ border: '3px solid #0d55c1', backgroundColor: 'transparent', zIndex: '100000000' }}
        outerAlpha={1}
        innerScale={0.7}
        outerScale={2}
      /> */}
      <Navbar />
      <Hero />
      <Whyus />
      <Features />
      {/* <Counter /> */}
      {/* <Contact /> */}
      <Footer />
      {/* <PrivacyPolicy /> */}
      {/* <TermsNC /> */}
    </div>
  );
}

export default App;
