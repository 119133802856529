import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.jpg";
import "./css/Navbar.css";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuStyle = {
    maxHeight: menuOpen ? '300px' : '0',
  };

  return (
    <div className="nav">
      <nav className="navbar">
        <div className="max-width">
          <div className="logo">
            <a
              href="https://www.decmeds.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="Decmeds" />
            </a>
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            <input className="menu-icon__cheeckbox" type="checkbox" checked={menuOpen} readOnly />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
          <div style={menuStyle} className="menu-items">
            <ul className="menu">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Features</a>
              </li>
              <li>
                <a href="#">Connect</a>
              </li>
            </ul>
            <div className="ai-btn font-15">
              <a href="#">
                Chat Now &nbsp;
                {/* <span className="arrow-right">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span> */}
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
